<template>
  <div class="contanier">
    <br><br><br><br>
    <router-link
      :to="{ name: 'home' }"
      style="color: #000"
    >
      <div class="d-flex align-items-center">
        <div class="box-back text-center mr-1">
          <i class="fas fa-long-arrow-left" />
        </div>
        <h5 class="text-center font-weight-bolder m-0">
          ย้อนกลับ
        </h5>
      </div>
    </router-link>
    <div class="head-all-games">
      <div class="">
        <h3 class="font-medium-2 font-weight-bolder m-0 text-center">
          {{ $route.params.productName.toUpperCase() }}
        </h3>
      </div>
    </div>
    <div class="back_bg">
      <div class="shadow_select_G p-0">
        <div class="row m-0">
          <div class="col-3 p-0 m-0">
            <button
              class="btns select_G_grid w-100 rounded-left"
              :class="{ activete: isActive }"
              @click="getProduct()"
            >
              <img
                src="/ic_game/casino.webp"
                class="icon_g"
              >
              <br>
              <span class="text-white">ทั้งหมด</span>
            </button>
          </div>
          <div class="col-3 p-0 m-0">
            <button
              class="btns select_G_grid w-100"
              :class="{ activete: isActive2 }"
              @click="getGame('SLOT')"
            >
              <img
                src="/ic_game/slot.webp"
                class="icon_g"
              >
              <br>
              <span class="text-white">สล็อต</span>
            </button>
          </div>
          <div class="col-3 p-0 m-0">
            <button
              class="btns select_G_grid w-100"
              :class="{ activete: isActive3 }"
              @click="getGame('EGAME')"
            >
              <img
                src="/ic_game/game.webp"
                class="icon_g"
              >
              <br>
              <span class="text-white">เกม</span>
            </button>
          </div>
          <div class="col-3 p-0 m-0">
            <button
              class="btns select_G_grid w-100 rounded-right"
              :class="{ activete: isActive4 }"
              @click="getGame('FISHING')"
            >
              <img
                src="/ic_game/fish.webp"
                class="icon_g"
              >
              <br>
              <span class="text-white">ยิงปลา</span>
            </button>
          </div>
        </div>
      </div>

      <div class="game-all mt-1">
        <div class="row pl-1 pr-1">
          <div
            v-for="games in gameList"
            :key="games.id"
            class="col-md-3 col-4 mb-3 p-0"
          >
            <div
              class="text-center image"
              @click="play(games.code)"
            >
              <div class="img-game-list">
                <img
                  :src="`${games.img}`"
                  alt="games"
                  class=""
                  style="border-radius: 10px"
                >
              </div>
              <div style="margin-top: 5px; font-size: 10px">
                {{ games.name }}
              </div>
            </div>
          </div>
        </div>
        <br><br><br><br>
      </div>
    </div>
  </div>
</template>
<script>
import BUID from 'uniquebrowserid'

export default {
  name: 'GameAll',
  data() {
    return {
      isActive: false,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      search: '',
      gameList: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      checkstatus: null,
      checktrun: null,
    }
  },
  mounted() {
    this.getProduct()
    this.updateBUID()
    this.statusUser()
    // this.TrunUser()
  },
  methods: {
    updateBUID() {
      const buid = new BUID().completeID()
      const formData = {
        buid,
      }
      this.$http.post('/users/updatebuid', formData)
    },
    getProduct() {
      this.isActive = true
      this.isActive2 = false
      this.isActive3 = false
      this.isActive4 = false
      // console.log(this.$route.params.productId)
      // console.log(this.$route.params.productName)
      this.$http
        .get(
          `/product/gamelist/${this.$route.params.productId}`,
        )
        .then(response => {
          console.log(response.data)
          this.show = false
          this.gameList = response.data
        })
        .catch(error => console.log(error))
    },
    getGame(game) {
      this.$http
        .get(
          `/product/game/${this.$route.params.productId}/${game}`,
        )
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.gameList = response.data

          if (game === 'SLOT') {
            this.isActive = false
            this.isActive2 = true
            this.isActive3 = false
            this.isActive4 = false
          } else if (game === 'EGAME') {
            this.isActive = false
            this.isActive2 = false
            this.isActive3 = true
            this.isActive4 = false
          } else if (game === 'FISHING') {
            this.isActive = false
            this.isActive2 = false
            this.isActive3 = false
            this.isActive4 = true
          }
        })
        .catch(error => console.log(error))
    },
    play(gameCode) {
      if (this.checkstatus === 2 || this.checkstatus === 0) {
        this.SwalError('ยูสเซอร์ของท่านถูกระงับ !!')
      } else {
        // console.log(this.$route.params.productId)
        const obj = {
          username: this.userData.username,
          productId: this.$route.params.productId,
          gameCode,
          sessionToken: this.userData.token,
        }
        this.$http
          .post('/product/login', obj)
          .then(response => {
          // console.log(response.data.data.url)
            this.show = false
            window.location.href = response.data.data.url
          })
          .catch(error => console.log(error))
      }
    },
    statusUser() {
      this.$http
        .get(
          `/users/show/${this.userData.userID}`,
        )
        .then(response => {
          this.checkstatus = response.data.status
        })
        .catch(error => console.log(error))
    },
    TrunUser() {
      this.$http
        .get(
          `/users/trun/${this.$route.params.productId}`,
        )
        .then(response => {
          this.checktrun = response.data.trun
        })
        .catch(error => console.log(error))
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style scoped>
.contanier {
  max-width: 960px;
  margin: 0 auto;
}
.head-all-games {
  background: linear-gradient(#626166, #3d3a3d);
  border: 3px solid transparent;
  padding: 10px 0;
  border-radius: 5px 5px 0 0;
  /* padding: 10px; */
}
.head-all-games .back {
  position: relative;
  left: 10px;
  margin-top: -33px;
  color: #fff;
  font-size: 20px;
}
.games_a {
  color: #fff;
  font-size: 1rem;
  margin-top: 15px;
  border-bottom: 3px solid #d31e6b;
}
.p-games {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}
/**************************/
.shadow_select_G {
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
  background: #350b4a;
}
.select_G_grid.active {
  background: #303030;
  color: #fff;
  text-shadow: 0 5px 20px #fff, -29px -40px 0 transparent;
}
.icon_g {
  width: 40px;
}
img {
  vertical-align: middle;
  border-style: none;
}
.w-100 {
  width: 100% !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.btns {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: var(--bg-) !important;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  /* border-radius: 0.25rem; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.activete {
  background-image: linear-gradient(180deg, #b969a9, #1d154f) !important;
  color: #fff;
}
.image {
  width: 100%;
  overflow: hidden;
}
.image img {
  width: auto;
  height: 85px;
}
</style>
